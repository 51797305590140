<template>
  <div class="">
    <div class="">
      <v-card class="">
        <!-- logo -->
        <v-card-title class="d-flex py-7 position-relative">
          GALLERIE ({{galleryll.nb_element}})
        </v-card-title>

        <!-- login form -->
        <template>
          <v-row dense >
            <v-col
              v-for="(item, n) in galleryll.listEntity"
              :key="n"
              class="d-flex child-flex"
              cols="4"
            >
              <v-img
                :src="item.uploaddir+'270_'+item.image"
                :lazy-src="require('@/assets/images/holder.jpg')"
                aspect-ratio="1" contain
                class="grey lighten-2 align-end"
                @click="postimage = item; dialog.preview = true"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
<!--                <v-card-actions class="text-center pa-1">
                  <v-btn small target="_blank" :href="item.uploaddir+''+item.image" color="primary" class="v-btn" >
                    <v-icon>{{icons.mdiDownload}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn small @click="share" class="v-btn" >
                    <v-icon>{{icons.mdiShareVariant}}</v-icon>
                  </v-btn>
                </v-card-actions>-->
              </v-img>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </div>

    <v-dialog
      v-model="loaders.download"
      persistent
      width="300"
    >
      <v-card
        color="secondary"
      >
        <v-card-text>
          Telechargement en cours ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-bottom-sheet style="z-index: 4100"
                    v-model="dialog.preview"
                    max-width="600"
                    class="pb-12"
    >
      <v-card class="pb-12" >
        <v-card-title>

          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog.preview = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          :src="postimage.uploaddir+postimage.image"
          :lazy-src="postimage.uploaddir+'270_'+postimage.image"
          contain
          class="grey lighten-2 align-end"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-card-text>
          {{postimage.name}}
          {{postimage.size}}
        </v-card-text>
        <v-card-actions class="text-center pa-1">
          <v-btn small target="_blank" :href="postimage.uploaddir+''+postimage.image" color="primary" class="v-btn" >
            <v-icon>{{icons.mdiDownload}}</v-icon>
            <!--                    <span class="hidden s-sm-only">Telecharger</span>-->
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn small @click="share" class="v-btn" >
            <v-icon>{{icons.mdiShareVariant}}</v-icon>
            <!--                    <span class="hidden-sm-only">Partager</span>-->
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiClose,
  mdiArrowRight,
  mdiShareVariant,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiDownload
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import WalletRefillComponent from '@/components/WalletRefillComponent'
import CommentComponent from "@/components/CommentComponent.vue";

export default {
  components: {CommentComponent},
  setup() {
    const galleryll = ref({next:1, listEntity:[]})
    const loaders = ref({download:false})
    const dialog = ref({preview:false})
    const postimage = ref({ })

    const init = () => {
      Drequest.api('lazyloading.dv-image?dfilters=on&folder.slug:eq=gallery&dsort=id desc')
        .param({
          'next': galleryll.value.next,
          'per_page': 24,
        }).get(response => {
          console.log(response)
          galleryll.value = response
        })
    }
    init()
// Using fetch
    const downloadImage  = async (item) => {
      loaders.value.download = true
      /*const image = await fetch(item.uploaddir+''+item.image)
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)*/

      const link = document.createElement('a')
      link.href = item.uploaddir+''+item.image
      link.download = item.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      loaders.value.download = false
    }
    const share = (item, title = 'Web Share API Draft', text = 'Take a look at this spec!') => {
      if (navigator.share) {
        navigator.share({
          title: '3AG Edition',
          text: 'Retrouvez encore plus d image sur la plateforme reader'
            +'https://reader.3ag-edition.com/gallery',
          url: item.uploaddir+'270_'+item.image,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        console.log('Share not supported on this browser, do it the old way.');
      }
    }
    return {
      downloadImage,
      share,

      galleryll,
      loaders,
      dialog,
      postimage,

      icons: {
        mdiClose,
        mdiDownload,
        mdiEyeOutline,
        mdiArrowRight,
        mdiShareVariant,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
